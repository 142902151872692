'use client';

import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { DialogPortal } from '@/components/layout/modals/DialogPortal';
import type { ModalContentType } from '@/components/layout/modals/types';

interface DialogContext {
  showDialog: (body: ModalContentType) => void;
  closeDialog: () => void;
}

export const DialogContext = createContext<DialogContext>({
  showDialog: () => {},
  closeDialog: () => {},
});

export default function useDialog() {
  return useContext(DialogContext);
}

export const DialogProvider = ({
  children,
}: {
  children: (showDialog: (args: ModalContentType) => void) => React.ReactNode;
}) => {
  const [errorMessage, setErrorMessage] = useState<ModalContentType>({
    title: undefined,
    body: undefined,
    children: undefined,
    handleBackButton: undefined,
  });
  const isMountedRef = useRef(false);
  const [isShowDialog, setShowDialog] = useState(false);

  const showDialog = (message: ModalContentType) => {
    if (isMountedRef.current) {
      setErrorMessage(message);
      setShowDialog(true);
    }
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <DialogContext.Provider value={{ showDialog, closeDialog }}>
      <DialogPortal isOpen={isShowDialog} props={errorMessage} />
      {children(showDialog)}
    </DialogContext.Provider>
  );
};
