import Image from 'next/image';
import { type Key } from 'react';
import { Link } from 'react-transition-progress/next';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './index.css';

type Props = {
  items: { key: Key; src: string; alt: string; href: string }[];
  isShownPagination?: boolean;
  isShownArrows?: boolean;
  slidesPerView: number;
  isAutoPlay?: boolean;
  gap?: number;
};

export const Slider = (props: Props) => {
  const {
    items: initialItems,
    isShownPagination = false,
    isShownArrows = false,
    slidesPerView,
    isAutoPlay = false,
    gap = 8,
  } = props;

  const shouldLoop = initialItems.length > 1;
  // NOTE: loopで必要な画像の枚数が最低3枚のため、2枚の場合は複製している
  const items = initialItems.length === 2 ? [...initialItems, ...initialItems] : initialItems;

  return (
    <div className='slider-outer'>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={gap}
        navigation={isShownArrows && { enabled: true }}
        slidesPerView={slidesPerView}
        pagination={
          isShownPagination && {
            enabled: true,
            clickable: true,
            type: 'bullets',
          }
        }
        autoplay={isAutoPlay && { delay: 2500, disableOnInteraction: false }}
        centeredSlides={true}
        loop={shouldLoop}
        watchSlidesProgress={true}
        preventInteractionOnTransition={true}
        className='slider-container'
      >
        {items.map((item) => (
          <SwiperSlide key={item.key}>
            <div className='slider-image-container'>
              <Link href={item.href} className='relative block w-full'>
                <div className='aspect-ratio-container'>
                  <Image
                    src={item.src}
                    alt={item.alt}
                    fill
                    style={{ objectFit: 'contain' }}
                    priority
                  />
                </div>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
